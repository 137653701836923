import {HttpErrorResponse} from '@angular/common/http';
import {ErrorHandler, Injectable, NgZone} from '@angular/core';
import {UiService} from './common/ui.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private uiService: UiService,
    private zone: NgZone,
  ) {}

  handleError(error: any) {
    if (!(error instanceof HttpErrorResponse)) {
      error = error.rejection;
    }


    this.zone.run(() =>
      this.uiService.showToast(
          error?.message || 'Undefined client error',
      ),
    );

    console.error('Error from Indopos error handler', error);
  }
}
