  <ng-container *ngIf="(user | async)?.uid">
  <div class="min-h-full">
    <mat-toolbar class="flex py-4">
      <span>Indopos Partner Portal</span>
      <span class="grow"></span>
      <div *ngIf="currentUser">
          Hi, {{currentUser}}
      </div>
      <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>
          more_vert
          </mat-icon>
      </button>
      <mat-menu #menu="matMenu">
          <button
          mat-menu-item
          (click)="logout()"
          matTooltip="Sign out from this account"
          >
          <mat-icon>logout</mat-icon>
          Logout
          </button>
      </mat-menu>
      </mat-toolbar>
    <router-outlet></router-outlet>
  </div>
</ng-container>

<ng-container *ngIf="!((user | async)?.uid)">
  <indosuara-login></indosuara-login>
</ng-container>





