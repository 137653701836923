import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Component, OnDestroy} from '@angular/core';
import {Auth, User, authState, signOut} from '@angular/fire/auth';
import {traceUntilFirst} from '@angular/fire/performance';
import {EMPTY, Observable, Subscription, map} from 'rxjs';
import {SubSink} from 'subsink';


@Component({
  selector: 'indosuara-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnDestroy {
  private readonly userDisposable: Subscription|undefined;
  public readonly user: Observable<User | null> = EMPTY;
  public readonly currentUser!: string;
  private subs = new SubSink();
  opened = false;


  constructor(
    private auth: Auth,
    public breakpointObserver: BreakpointObserver,
  ) {
    if (auth) {
      this.user = authState(this.auth);
      this.currentUser = auth.currentUser?.displayName ??'';
      this.userDisposable = authState(this.auth).pipe(
          traceUntilFirst('auth'),
          map((u) => !!u),
      ).subscribe();
    }
  }


  ngOnDestroy(): void {
    if (this.userDisposable) {
      this.userDisposable.unsubscribe();
    }
  }

  get isSmall() {
    return this.breakpointObserver.isMatched(Breakpoints.XSmall);
  }

  async logout() {
    return await signOut(this.auth);
  }
}
