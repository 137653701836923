import {RouterModule, Routes} from '@angular/router';

import {NgModule} from '@angular/core';
import {environment} from '../environments/environment';
import {HomeComponent} from './home/home.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';

let devRoutes: Routes = [];

if (!environment.production) {
  devRoutes = [];
}

const routes: Routes = [
  {
    path: '',
    children:
    [
      {
        path: '',
        component: HomeComponent,
      },
    ],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot([...devRoutes, ...routes])],
  exports: [RouterModule],
})

/**
 * Main app routing module
 */
export class AppRoutingModule {}
