import {Component, Inject} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  template: `
    <h2 mat-dialog-title>{{ data.title }}</h2>
    <mat-dialog-content>
      <p>{{ data.content }}</p>
      <mat-form-field appearance="outline">
        <textarea matInput
            cdkTextareaAutosize
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"
            type="text"  [formControl]="reason">
        </textarea>
        <mat-error>required</mat-error>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
      <span class="flex-spacer"></span>
      <button mat-button mat-dialog-close *ngIf="data.cancelText">
        {{ data.cancelText }}
      </button>
      <button
      mat-button
      mat-button-raised
      color="primary"
      (click)="onSubmit()"
      cdkFocusInitial
      >
        {{ data.okText }}
      </button>
    </mat-dialog-actions>
  `,
})

export class AskTextDialogComponent {
  reason = new FormControl<string>('', {
    nonNullable: true,
    validators: [
      Validators.required,
    ],
  });
  constructor(
    public dialogRef: MatDialogRef<AskTextDialogComponent, string>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  onSubmit() {
    if (this.reason.invalid) {
      this.reason.markAllAsTouched();
      return;
    }
    this.dialogRef.close(this.reason.value);
  }
}
