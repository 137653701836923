import {Component} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {PageEvent} from '@angular/material/paginator';
import * as moment from 'moment';
import {BehaviorSubject, Observable, map, merge, startWith, switchMap, tap} from 'rxjs';
import {IndoposInvoice, IndoposInvoiceStatus, IndoposSalesService} from '../indopos-sales.service';

@Component({
  selector: 'indosuara-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent {
  constructor(
    private salesService: IndoposSalesService,
  ) {
    this.sales$ = merge(
        this.page$.asObservable(),
        this.pageSize$.asObservable(),
        this.filters.valueChanges,
    ).pipe(
        startWith({}),
        switchMap(()=> this.salesService.queryInvoices(
            {
              page: this.page$.value + 1, pageSize: this.pageSize$.value,
              filters: {
                status: [this.filters.value.status ?? 'valid'],
                id: this.filters.value.transactionId,
                memberName: this.filters.value.name,
                memberPhone: this.filters.value.phone,
                boxId: this.filters.value.boxId,

              },
              range: {
                createdAt: {
                  startDate: this.filters.value.range?.start ?? undefined,
                  endDate: moment(
                      this.filters.value.range?.end)
                      .endOf('d').toISOString() ?? undefined,
                },
              },
            },

        )),
        tap((v)=> this.totalData = v.totalData),
        map((v)=> v.body),
    );
  }

  sales$ : Observable<IndoposInvoice[]>;
  totalData = 0;

  displayedColumns = [
    'time',
    'transactionId',
    'salesStatus',
    'customer',
    'value',
    'detail',
  ];

  filters = new FormGroup({
    name: new FormControl(''),
    phone: new FormControl(''),
    transactionId: new FormControl(''),
    boxId: new FormControl(''),
    range: new FormGroup({
      start: new FormControl<string | undefined>(undefined),
      end: new FormControl<string | undefined>(undefined),
    }),
    status: new FormControl<IndoposInvoiceStatus>('valid'),
  });

  private page$ = new BehaviorSubject(0);
  private pageSize$ = new BehaviorSubject(10);

  get pageSize() {
    return this.pageSize$.value;
  }
  get page() {
    return this.page$.value;
  }

  onPaginateChange(event: PageEvent) {
    this.page$.next(event.pageIndex);
    this.pageSize$.next(event.pageSize);
  }


  generateDetailText(element: IndoposInvoice): string {
    if (element.newBox) {
      return 'Penjualan Kardus';
    }
    if (element.boxes?.filter((v)=> v.needPickup == 'Y')) {
      return 'Penjemputan dan Pelunasan Kardus';
    }
    if (element.boxes) {
      return 'Pelunasan Kardus';
    }
    return '';
  }


  exportToExcel() {
    this.salesService.renderSalesXlsReport(
        {
          page: 1,
          pageSize: 1000,
          filters: {
            status: [this.filters.value.status ?? 'valid'],
            id: this.filters.value.transactionId,
            memberName: this.filters.value.name,
            memberPhone: this.filters.value.phone,
            boxId: this.filters.value.boxId,

          },
        },
    );
  }
}
