
  <div class="flex sm:flex-row flex-col p-2 gap-4 flex-wrap" [formGroup]="filters">
    <div>
      <label for="status" class="block text-sm font-medium leading-6 text-gray-900">Status</label>
      <select id="status" name="status" formControlName="status" class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-orange-600 sm:text-sm sm:leading-6">
        <option value="valid">All</option>
        <option value="pending">Pending</option>
        <option value="shipped">Shipped</option>
        <option value="completed">Completed</option>
        <option value="invoiced">Invoiced</option>
        <option value="cancelled">Cancelled</option>
        <option value="rejected">Rejected</option>
        <option value="deleted">Deleted</option>
      </select>
    </div>
    <div>
      <div class="flex flex-col items-start gap-2">
        <label class="block text-sm font-medium leading-6 text-gray-900">Transaction</label>
        <input formControlName="transactionId" type="text" class="rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 " placeholder="T1120101AAAAAA">
      </div>
    </div>
    <div>
      <div class="flex flex-col items-start gap-2">
        <label class="block text-sm font-medium leading-6 text-gray-900">Box Tracking Number</label>
        <input formControlName="boxId"  type="text" class="rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 " placeholder="1232303000001">
      </div>
    </div>
    <div class="flex flex-col items-start gap-2">
      <label class="block text-sm font-medium leading-6 text-gray-900">Customer</label>
      <input formControlName="name" type="text" class="rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 " placeholder="Name">
      <input formControlName="phone" type="text"  class="rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 " placeholder="Phone">
    </div>
    <div class="flex flex-col items-start gap-2">
        <label class="block text-sm font-medium leading-6 text-gray-900">Date</label>
        <div class="flex flex-row items-center">
          <mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-input [formGroup]="filters.controls.range" [rangePicker]="picker" >
            <input matStartDate formControlName="start" placeholder="YYYY/MM/DD" >
            <input matEndDate formControlName="end" placeholder="YYYY/MM/DD">
          </mat-date-range-input>
        </div>
        <div *ngIf="filters.controls.range.controls.start.hasError('matStartDateInvalid')" class="block text-xs leading-6 text-red-500">Invalid start date</div>
        <div *ngIf="filters.controls.range.controls.end.hasError('matEndDateInvalid')" class="block text-xs  leading-6 text-red-500">Invalid end date</div>
        <mat-date-range-picker #picker></mat-date-range-picker>
    </div>
    <div class="flex flex-col items-start justify-center ml-8">
      <button mat-flat-button color="primary" (click)="exportToExcel()">
        <mat-icon>
          download
        </mat-icon>
        Export Excel
      </button>
    </div>

  </div>

  <div class="table-container">
    <table *ngIf="sales$ | async as sales" mat-table [dataSource]="sales">
      <ng-container matColumnDef="time">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >
          Date
        </th>
        <td mat-cell *matCellDef="let element">
          {{element.createdAt | date : 'yyyy/MM/dd'}}
        </td>
      </ng-container>
      <ng-container matColumnDef="transactionId">
        <th mat-header-cell *matHeaderCellDef  >
          Transaction ID
        </th>
        <td mat-cell *matCellDef="let element">
          {{element.id}}

        </td>
      </ng-container>
      <ng-container matColumnDef="salesStatus">
        <th mat-header-cell *matHeaderCellDef  >
          Sales Status
        </th>
        <td mat-cell *matCellDef="let element">
          <span
            [ngClass]="[
            (element.salesStatus === 'invoiced') ?'text-green-800 bg-green-100' : '',
            (element.salesStatus === 'completed') ?'text-green-800 bg-green-100' : '',
            (element.salesStatus === 'shipped') ?'text-orange-800 bg-orange-100' : '',
            (element.salesStatus === 'pending') ?'text-red-800 bg-red-100' : '',
            ]"
            class="inline-flex rounded-full px-2 text-xs font-semibold leading-5 ">
            {{element.salesStatus}}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="customer">
        <th mat-header-cell *matHeaderCellDef  >
          Customer
        </th>
        <td mat-cell *matCellDef="let element">
          <div>
            {{element.memberName}}
          </div>
          <div class="text-gray-500 font-light">
            {{element.memberPhone}}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="detail">
        <th mat-header-cell *matHeaderCellDef  >
          About
        </th>
        <td mat-cell *matCellDef="let element">
          {{generateDetailText(element)}}
          <button [matMenuTriggerFor]="menu">
            ...
          </button>

          <mat-menu #menu="matMenu" >
            <div  class="block p-4 hover:text-orange-600" *ngFor="let item of element.boxes">{{item.boxId}}</div>
            <div *ngIf="!element.boxes" class="block p-4 hover:text-orange-600" >
              Box Pending
            </div>
          </mat-menu>
        </td>
      </ng-container>
      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef  >
          <div class="text-right ">
            Price
          </div>
        </th>
        <td mat-cell *matCellDef="let element" >
          <div class="text-right ">
            <span class="text-gray-500 font-light text-xs">$</span> {{element.totalPrice | number}}  <span class="text-gray-500 font-light text-xs">NT</span>

          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [length]="totalData"
                [pageSize]="pageSize"
                [pageIndex]="page"
                [pageSizeOptions]="[5, 10, 20, 100]"
                (page)="onPaginateChange($event)"
                >
    </mat-paginator>
  </div>
