import * as _ from 'lodash';
import * as moment from 'moment';

import {Observable, filter, map, switchMap, tap, throwError} from 'rxjs';

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../environments/environment';
import {UiService} from './common/ui.service';

export interface IndoposResponse {
  status?: string;
  code?: number;
  message?: string;
  error?: string;
}

export const INDOPOS_DISPLAY_TEXT: Record<string, string> = {
  cod: 'Cash on Delivery',
  indosuaraShop: 'Toko Indosuara',
  heimaoPay: '黑貓Pay',
  hct: 'HCT',
  kerry: 'Kerry',
  heimao: '黑貓HeiMao',
  paymentOnly: 'Payment Only',
};

export interface IndoposInvoices {
  totalData: number;
  body: IndoposInvoice[];
}

export type IndoposInvoice = IndoposInvoiceInfo & IndoposInvoiceUpdatableInfo;

export interface IndoposInvoiceInfo {
  id: string;
  createdAt: string;
  createdBy: string;
  totalPrice: number;
  generatedAt?: string;
  generatedBy?: string;
  updatedAt?: string;
  updatedBy?: string;
  shippedAt?: string;
  shippedBy?: string;
  salesStatus?: string;
  isDeleted?: boolean;
  packageNo?: string;
  invoiceNo?: string;
  invoiceTime?: string;
  completedAt?: string;
  completedBy?: string;
}

export type IndoposInvoiceUpdatableInfo = IndoposInvoiceMemberInfo & {
  paymentMethod: string;
  originCode?: string;
  shippingMethod: string;
  notes?: string;
  newBox?: IndoposSalesNewBoxes[];
  boxes?: IndoposSalesBox[];
  extras?: IndoposSalesExtra[];
  attachments?: string[];
};

export interface IndoposSalesExtra {
  name: string;
  quantity: number;
  price: number;
}

export interface IndoposInvoiceMemberInfo {
  memberName: string;
  memberPhone: string;
  memberAddress: string;
}

export interface IndoposSalesNewBoxes {
  boxType: number;
  area: string;
  initialPayment: number;
  isPromo?: string;
  hasBp2mi: string;
}

export interface IndoposSalesBox {
  boxId: string;
  paid: number;
  needPickup?: string;
}

export interface IndoposInvoiceQueryFilters {
  id?: string | null;
  boxId?: string | null;
  originCode?: string | null;
  memberName?: string | null;
  memberPhone?: string | null;
  memberAddress?: string | null;
  packageNo?: string | null;
  notes?: string | null;
  status?: IndoposInvoiceStatus[] | null;
  salesIds?: string[] | null;
  needPickup?: 'Y' | 'N' | '' | null;
  paymentMethod?: IndoposPaymentMethod | null;
  shippingMethod?: IndoposShippingMethod | null;
}

export type IndoposPaymentMethod =
  | 'cod'
  | 'transfer'
  | 'heimaoPay'
  | 'indosuaraShop'
  | '';

export type IndoposShippingMethod =
  | 'hct'
  | 'kerry'
  | 'heimao'
  | 'internal'
  | 'paymentOnly'
  | '';

export type IndoposInvoiceStatus =
  | 'pending'
  | 'shipped'
  | 'completed'
  | 'invoiced'
  | 'valid'
  | 'deleted';

export interface IndoposInvoiceRangeFilters {
  createdAt?: {
    startDate?: string;
    endDate?: string;
  };
  generatedAt?: {
    startDate?: string;
    endDate?: string;
  };
  completedAt?: {
    startDate?: string;
    endDate?: string;
  };
  invoiceTime?: {
    startDate?: string;
    endDate?: string;
  };
}

export interface IndoposSalesImageUploadResponse {
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class IndoposSalesService {
  constructor(private http: HttpClient, private uiService: UiService) {}

  getInvoice(id: string): Observable<IndoposInvoice> {
    if (id.length != 20 && id.length != 14) {
      return throwError(() => Error('invoice not found'));
    }
    return this.queryInvoices({
      page: 1,
      pageSize: 1,
      filters: {
        id: id,
      },
    }).pipe(
        map((v) => {
          if (v.body == undefined) throw Error('invoice not found');
          if (v.body.length == 0) throw Error('invoice not found');
          return v.body[0];
        }),
    );
  }

  queryInvoices(params: {
    page: number;
    pageSize: number;
    sortColumn?: string;
    sortDirection?: '' | 'asc' | 'desc';
    filters?: IndoposInvoiceQueryFilters;
    range?: IndoposInvoiceRangeFilters;
  }): Observable<IndoposInvoices> {
    return this.http
        .post<IndoposResponse & IndoposInvoices>(
            environment.indosuaraApiUrl + '/indopos/sales/v0/partner/sales',
            {
              pageNumber: params.page,
              pageSize: params.pageSize,
              sortBy: params.sortColumn,
              sortDirection: params.sortDirection == 'asc' ? 1 : -1,
              filter: params.filters,
              range: params.range,
            },
        )
        .pipe(
            tap((value) => {
              if (value.error) throw Error(value.error);
            }),
        );
  }

  renderSalesXlsReport(params: {
    page: number;
    pageSize: number;
    sortColumn?: string;
    sortDirection?: '' | 'asc' | 'desc';
    filters?: IndoposInvoiceQueryFilters;
    range?: IndoposInvoiceRangeFilters;
  }) {
    this.uiService
        .showDialog('Export Excel', '', 'Render', 'Cancel')
        .pipe(
            filter((v) => v),
            switchMap(() => {
              return this.queryInvoices(params);
            }),
            map((v) => v.body),
        )
        .subscribe({
          next: (value) => {
            const newValue = value.map((v) => {
              return {
                id: v.id || '',
                createdAt: moment(v.createdAt).format('YYYY-MM-DD') || '',
                Status: v.salesStatus || '',
                customerName: v.memberName || '',
                customerPhone: v.memberPhone || '',
                Price: v.totalPrice || 0,
              };
            });
            const aoa = this._transformObjectToAoA(newValue);
            this._writeXlsFromAoa(aoa);
          },
        });
  }

  private _writeXlsFromAoa(aoa: (string | number)[][]) {
    this._writeToXls(aoa, 'Sales', 'Partner');
  }

  private _transformObjectToAoA(reports: object[]) {
    const keys = Object.keys(reports[0]).map((k) => _.startCase(k));
    const aoa: (string | number)[][] = [keys];
    aoa.push(
        ...reports.map((struct) => {
          return _.values(_.mapValues(struct, (value) => value));
        }),
    );
    return aoa;
  }

  private _writeToXls(
      aoa: (string | number)[][],
      sheetName: string,
      fileName: string,
  ) {
    return import('xlsx').then((XLSX) => {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(aoa);
      XLSX.utils.book_append_sheet(wb, ws, sheetName);

      XLSX.writeFile(wb, this._generateFileName(fileName), {
        bookType: 'biff8',
      });
    });
  }

  private _generateFileName(name: string) {
    const localISOTime = this._newDateStringISOLocale();

    const fileName = `indopos-${name}-report-${localISOTime}.xls`;
    return fileName;
  }

  private _newDateStringISOLocale() {
    const tzoffset = new Date().getTimezoneOffset() * 60000;
    const localISOTime = new Date(Date.now() - tzoffset)
        .toISOString()
        .slice(0, -1);
    return localISOTime;
  }
}
