import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AskTextDialogComponent} from './ask-text-dialog.component';
import {SimpleDialogComponent} from './simple-dialog.component';

@Injectable({
  providedIn: 'root',
})
/**
 *
 */
export class UiService {
  /**
   * UI Service that manage material's snackbar or dialog
   * @param {MatSnackBar}snackBar
   * @param {MatDialog}dialog
   */
  constructor(private snackBar: MatSnackBar, private dialog: MatDialog) {}

  showToast(message: string, action = 'Close', config?: MatSnackBarConfig) {
    this.snackBar.open(
        message,
        action,
        config || {verticalPosition: 'bottom', duration: 7000},
    );
  }


  showDialog(
      title: string,
      content: string,
      okText = 'OK',
      cancelText?: string,
      customConfig?: MatDialogConfig,
  ): Observable<boolean> {
    const dialogRef = this.dialog.open(
        SimpleDialogComponent,
        customConfig || {
          width: '350px',
          data: {title, content, okText, cancelText},
        },
    );

    return dialogRef.afterClosed();
  }
  showAskTextDialog(
      title: string,
      content: string,
      okText = 'OK',
      cancelText?: string,
      customConfig?: MatDialogConfig,
  ): Observable<string> {
    const dialogRef = this.dialog.open(
        AskTextDialogComponent,
        customConfig || {
          width: '350px',
          data: {title, content, okText, cancelText},
        },
    );
    return dialogRef.afterClosed();
  }
}
