import {Component} from '@angular/core';
import {Auth, signInAnonymously, signInWithEmailAndPassword, signOut} from '@angular/fire/auth';
import {FormControl, FormGroup} from '@angular/forms';
@Component({
  selector: 'indosuara-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  constructor(private auth: Auth) {}

  loginForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });
  async login() {
    const email = this.loginForm.value.email;
    const password = this.loginForm.value.password;
    if (!email) return;
    if (!password) return;
    return await signInWithEmailAndPassword(
        this.auth,
        email,
        password,
    );
  }

  async loginAnonymously() {
    return await signInAnonymously(this.auth);
  }

  async logout() {
    return await signOut(this.auth);
  }
}
