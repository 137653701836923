import {CdkMenuModule} from '@angular/cdk/menu';
import {TextFieldModule} from '@angular/cdk/text-field';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';

import {MatPaginatorModule} from '@angular/material/paginator';

import {MatMomentDateModule} from '@angular/material-moment-adapter';

const modules = [
  MatButtonModule,
  MatCardModule,
  MatDialogModule,
  MatDividerModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatSidenavModule,
  MatSnackBarModule,
  MatToolbarModule,
  MatDividerModule,
  MatTooltipModule,
  MatTreeModule,
  TextFieldModule,
  CdkMenuModule,
  MatTableModule,
  MatPaginatorModule,
  MatDatepickerModule,
  MatMomentDateModule,
];
@NgModule({
  exports: modules,
})

/**
 * Material Module
 */
export class MaterialModule {}
